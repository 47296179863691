import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getStudentDetails } from "../../actions/Student.action";
import AddStudentForm from "../../components/AddStudentForm/AddStudentForm";
import Layout from "../../components/shared/Layout/Layout";

const AddStudentPage = ({ role, edit, student, getStudentDetails }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (edit && id) {
      getStudentDetails(id);
    }
    if (!role || role !== "ADMIN") {
      navigate("/");
    }
  }, [id]);

  return (
    <div className="bg_light">
      <Layout title={`${edit ? "Edit" : "Add"} User`}>
        {edit && id && student ? (
          <AddStudentForm edit={true} data={student} />
        ) : (
          <AddStudentForm />
        )}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  student: state.student.selected_student,
  role: state.auth.role,
});

export default connect(mapStateToProps, { getStudentDetails })(AddStudentPage);
