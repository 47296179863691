export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const ACCESS_TOKEN_ERROR = "ACCESS_TOKEN_ERROR";
export const ACCESS_TOKEN_SUCCESS = "ACCESS_TOKEN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const PASSWORD_CHANGED_ERROR = "PASSWORD_CHANGED_ERROR";

//PROFILE REDUCER
export const AUTH_USER_LOAD = "AUTH_USER_LOAD";
export const AUTH_USER_LOAD_ERROR = "AUTH_USER_LOAD_ERROR";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const PROFILE_UPDATE_ERROR = "PROFILE_UPDATE_ERROR";

// STUDENT REDUCER
export const CREATE_STUDENT = "CREATE_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const GET_STUDENT_DETAILS = "GET_STUDENT_DETAILS";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const GET_STUDENT = "GET_STUDENT";

// THESIS REDUCER
export const CREATE_THESIS = "CREATE_THESIS";
export const DELETE_THESIS = "DELETE_THESIS";
export const GET_THESIS_DETAILS = "GET_THESIS_DETAILS";
export const UPDATE_THESIS = "UPDATE_THESIS";
export const GET_THESIS = "GET_THESIS";
