import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../../actions/Dashboard.action";
import { Badge, Button } from "@mantine/core";
import { useModals } from "@mantine/modals";
import Profile from "../../Profile/Profile";
import { FaCog, FaHome, FaSignOutAlt } from "react-icons/fa";

const NavbarComp = ({ user, logout, auth }) => {
  const navigate = useNavigate();
  const modals = useModals();
  const logoutHandeler = async () => {
    let check = await logout();
    if (check === true) {
      navigate("/");
    }
  };

  const profileHandeler = () => {
    modals.openModal({
      title: auth.name,
      centered: true,
      children: (
        <Profile
          modals={modals}
          data={{ name: auth.name, email: auth.email, id: auth.id }}
        />
      ),
      closeOnClickOutside: false,
    });
  };
  return (
    <Navbar expand="lg" className="bg-white shadow">
      <Container fluid>
        <Navbar.Brand as={NavLink} to="/">
          Mechanical Engineering Theses Collection
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>
          {auth && auth?.isAuthenticated && auth?.name && auth?.role ? (
            <>
              <span className={"nav-link mx-2 fw-normal"}>
                {auth.name}{" "}
                <Badge
                  variant="outline"
                  style={{ textTransform: "capitalize" }}
                >
                  {auth.role}
                </Badge>
              </span>
              <Button
                color="pink"
                onClick={() => profileHandeler()}
                className="mx-2"
                leftIcon={<FaCog />}
              >
                Settings
              </Button>
              {auth.role === "ADMIN" ? (
                <Button
                  color="teal"
                  onClick={() => navigate("/dashboard")}
                  className="mx-2"
                  leftIcon={<FaHome />}
                >
                  Admin Dashboard
                </Button>
              ) : (
                <></>
              )}
              <Button leftIcon={<FaSignOutAlt />} onClick={logoutHandeler}>
                Logout
              </Button>
            </>
          ) : (
            <>
              <NavLink end className={"nav-link mx-2 fw-normal"} to="/">
                Home
              </NavLink>
              <NavLink end className={"nav-link fw-normal"} to="/login">
                Login
              </NavLink>
            </>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(NavbarComp);
