import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_STUDENT,
  DELETE_STUDENT,
  GET_STUDENT,
  GET_STUDENT_DETAILS,
  UPDATE_STUDENT,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";
import { JSON_POST_CONFIG } from "../constants/PostConfig";
import { authUserAction } from "./Dashboard.action";

//GET STUDENT LIST
export const getStudentList = (role) => async (dispatch) => {
  try {
    const data = {
      role,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `${BASE_URL}/api/user/filter`,
      JSON.stringify(data),
      config
    );

    dispatch({
      type: GET_STUDENT,
      payload: res.data.data,
    });

    return res.data.data;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
//GET STUDENT Details
export const getStudentDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_URL}/api/user/${id}`);

    dispatch({
      type: GET_STUDENT_DETAILS,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

// CREATE STUDENT
export const createStudent = (values) => async (dispatch) => {
  try {
    // TODO ::: API CALL
    const res = await axios.post(
      `${BASE_URL}/api/user`,
      JSON.stringify(values),
      JSON_POST_CONFIG
    );
    dispatch({
      type: CREATE_STUDENT,
    });
    toast.success("User added successfully");

    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

// UPDATE STUDENT
export const updateUser = (values, id) => async (dispatch) => {
  try {
    // TODO ::: API CALL
    const res = await axios.patch(
      `${BASE_URL}/api/user/${id}`,
      JSON.stringify(values),
      JSON_POST_CONFIG
    );
    dispatch({
      type: UPDATE_STUDENT,
    });

    dispatch(authUserAction());

    toast.success("User updated successfully");
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

//DELETE Student
export const deleteStudent = (id) => async (dispatch) => {
  try {
    await axios.delete(`${BASE_URL}/api/user/${id}`);
    dispatch({
      type: DELETE_STUDENT,
      payload: id,
    });
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
