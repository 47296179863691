import {
  DELETE_THESIS,
  GET_THESIS,
  GET_THESIS_DETAILS,
} from "../constants/Type";

const initialState = {
  thesis: null,
  selected_thesis: null,
  loading: true,
};

const thesisReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_THESIS:
      return {
        ...state,
        thesis: payload,
        loading: false,
      };

    case GET_THESIS_DETAILS:
      return {
        ...state,
        selected_thesis: payload,
        loading: false,
      };

    case DELETE_THESIS:
      return {
        ...state,
        thesis: state.thesis.filter((grd) => grd.id !== parseInt(payload)),
        loading: false,
      };

    default:
      return state;
  }
};

export default thesisReducer;
