import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getThesisDetails } from "../../actions/Thesis.action";
import AddThesisForm from "../../components/AddThesisForm/AddThesisForm";
import Layout from "../../components/shared/Layout/Layout";

const AddThesisPage = ({ edit, thesis, getThesisDetails, role }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (edit && id) {
      getThesisDetails(id);
    }
    if (!role || role !== "ADMIN") {
      navigate("/");
    }
  }, [id]);

  return (
    <div className="bg_light">
      <Layout title={`${edit ? "Edit" : "Add"} Thesis`}>
        {edit && id && thesis ? (
          <AddThesisForm edit={true} data={thesis} />
        ) : (
          <AddThesisForm />
        )}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  thesis: state.thesis.selected_thesis,
  role: state.auth.role,
});

export default connect(mapStateToProps, { getThesisDetails })(AddThesisPage);
