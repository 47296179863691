import React, { useEffect } from "react";
import Layout from "../../components/shared/Layout/Layout";
import { StudentList } from "../../components/StudentList";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const StudentListPage = ({ role }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!role || role !== "ADMIN") {
      navigate("/");
    }
  }, [role]);
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title="Users">
        <StudentList />
      </Layout>
    </div>
  );
};
const mapStateToProps = (state) => ({
  role: state.auth.role,
});
export default connect(mapStateToProps, null)(StudentListPage);
