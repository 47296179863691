import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import {
  Form as BootstrapForm,
  Button,
  Card,
  Col,
  Container,
  InputGroup,
  Row,
} from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { getDashboardData } from "../../actions/Dashboard.action";
import {
  createStudent,
  getStudentList,
  updateUser,
} from "../../actions/Student.action";
import { ROLES } from "../../constants/roles";
import styles from "./AddStudentForm.module.css";

const AddStudentForm = ({
  createStudent,
  data,
  edit,
  updateUser,
  getStudentList,
  getDashboardData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let type = searchParams.get("role") ? searchParams.get("role") : "STUDENT";

  const onSubmitHandeler = async (values) => {
    setIsLoading(true);
    let check = edit
      ? await updateUser(values, data.id)
      : await createStudent(values);
    if (check === true) {
      setTimeout(() => {
        setIsLoading(false);
        getStudentList();
        getDashboardData();
        navigate(`/admin/user?role=${type}`);
      }, 1000);
    } else {
      setIsLoading(false);
    }
  };

  let initVals = {
    name: edit === true && data ? data.name : "",
    email: edit === true && data ? data.email : "",
    password: edit === true && data ? data.password : "",
    role: edit === true && data && data.role ? data?.role : type,
  };

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("Student Name is required!"),
    email: Yup.string()
      .email("Enter valid email")
      .required("Email is required!"),
    password: Yup.string().required("Password  is required!"),
  });
  return (
    <Container className="pb-5">
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <h1 className="fs-4 fw-normal py-3">
            Fill the form to {edit ? "edit" : "add"} user
          </h1>
          <Formik
            initialValues={initVals}
            validationSchema={SignupSchema}
            enableReinitialize
            onSubmit={(values) => onSubmitHandeler(values)}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="name" className="d-block">
                      Name
                    </label>
                    {errors.name && touched.name ? (
                      <small className="text-danger pt-2">{errors.name}</small>
                    ) : null}
                  </div>
                  <Field
                    as={BootstrapForm.Control}
                    placeholder="Type name ..."
                    name="name"
                    isValid={!errors.name && touched.name}
                    type="text"
                    className={`${styles.input} w-100`}
                    isInvalid={errors.name && touched.name}
                  />
                </InputGroup>
                <Row>
                  <Col md={6}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center ">
                        <label htmlFor="email" className="d-block">
                          Email
                        </label>
                        {errors.email && touched.email ? (
                          <small className="text-danger pt-2">
                            {errors.email}
                          </small>
                        ) : null}
                      </div>
                      <Field
                        as={BootstrapForm.Control}
                        placeholder="Type email..."
                        name="email"
                        type="email"
                        className={`${styles.input} w-100`}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center ">
                        <label htmlFor="password" className="d-block">
                          Password
                        </label>
                        {errors.password && touched.password ? (
                          <small className="text-danger pt-2">
                            {errors.password}
                          </small>
                        ) : null}
                      </div>
                      <Field
                        as={BootstrapForm.Control}
                        placeholder="Type password..."
                        name="password"
                        type="password"
                        className={`${styles.input} w-100`}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center">
                        <label htmlFor="role" className="d-block">
                          Role
                        </label>
                        {errors.role && touched.role ? (
                          <small className="text-danger pt-2">
                            {errors.role}
                          </small>
                        ) : null}
                      </div>
                      <BootstrapForm.Select
                        role="role"
                        isValid={!errors.role && touched.role}
                        className={`${styles.input} w-100`}
                        isInvalid={errors.role && touched.role}
                        onChange={(e) => {
                          setFieldValue("role", e.target.value);
                        }}
                        value={values.role}
                      >
                        {ROLES.map((item, i) => (
                          <option key={i} value={item}>
                            {" "}
                            {item}
                          </option>
                        ))}
                      </BootstrapForm.Select>
                    </InputGroup>
                  </Col>
                </Row>

                <div className="pt-4">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn_primary"
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Save"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default connect(null, {
  createStudent,
  updateUser,
  getStudentList,
  getDashboardData,
})(AddStudentForm);
