import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import {
  Form as BootstrapForm,
  Button,
  Col,
  InputGroup,
  Row,
} from "react-bootstrap";
import * as Yup from "yup";
import { updateUser } from "../../actions/Student.action";
import styles from "./Profile.module.css";
import { connect } from "react-redux";
import PasswordChangeForm from "../PasswordChangeForm/PasswordChangeForm";

const Profile = ({ data, modals, updateUser }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandeler = async (values) => {
    setIsLoading(true);
    let check = await updateUser(values, data.id);
    if (check === true) {
      setTimeout(() => {
        setIsLoading(false);
        modals.closeAll();
      }, 1000);
    } else {
      setIsLoading(false);
    }
  };
  let initVals = {
    name: data.name,
    email: data.email,
  };
  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("Student Name is required!"),
    email: Yup.string()
      .email("Enter valid email")
      .required("Email is required!"),
  });
  return (
    <div>
      <Formik
        initialValues={initVals}
        validationSchema={SignupSchema}
        enableReinitialize
        onSubmit={(values) => onSubmitHandeler(values)}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <InputGroup className="mb-3 d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center">
                <label htmlFor="name" className="d-block">
                  Name
                </label>
                {errors.name && touched.name ? (
                  <small className="text-danger pt-2">{errors.name}</small>
                ) : null}
              </div>
              <Field
                as={BootstrapForm.Control}
                placeholder="Type name..."
                name="name"
                isValid={!errors.name && touched.name}
                type="text"
                className={`${styles.input} w-100`}
                isInvalid={errors.name && touched.name}
              />
            </InputGroup>
            <Row>
              <Col md={12}>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center ">
                    <label htmlFor="email" className="d-block">
                      Email
                    </label>
                    {errors.email && touched.email ? (
                      <small className="text-danger pt-2">{errors.email}</small>
                    ) : null}
                  </div>
                  <Field
                    as={BootstrapForm.Control}
                    placeholder="Type email..."
                    name="email"
                    type="email"
                    className={`${styles.input} w-100`}
                  />
                </InputGroup>
              </Col>
            </Row>

            <div className="d-flex justify-content-between pt-4">
              <Button
                variant="primary"
                type="submit"
                className="btn_primary"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Save"}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  modals.openModal({
                    title: "Password Chnage",
                    centered: true,
                    children: <PasswordChangeForm modals={modals} />,
                    closeOnClickOutside: false,
                  });
                }}
                className="btn_primary"
                disabled={isLoading}
              >
                Change Password
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default connect(null, { updateUser })(Profile);
