import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getThesisDetails } from "../../actions/Thesis.action";
import ThesisDetails from "../../components/ThesisDetails/ThesisDetails";
import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";

const ThesisDetailsPage = ({ edit, isAuth }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuth) {
      toast("Please login to view thesis content");
      navigate(-1);
    }
  }, [id, isAuth]);

  if (!isAuth) {
    return <></>;
  }

  return (
    <div className="bg_light">
      <PublicLayout>
        <ThesisDetails id={parseInt(`${id}`)} />
      </PublicLayout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { getThesisDetails })(
  ThesisDetailsPage
);
