import {
  CREATE_STUDENT,
  DELETE_STUDENT,
  GET_STUDENT,
  GET_STUDENT_DETAILS,
  UPDATE_STUDENT,
} from "../constants/Type";

const initialState = {
  student: null,
  selected_student: null,
  loading: true,
};

const studentReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_STUDENT:
      return {
        ...state,
        student: payload,
        loading: false,
      };
    case GET_STUDENT_DETAILS:
      return {
        ...state,
        selected_student: payload,
        loading: false,
      };

    case DELETE_STUDENT:
      return {
        ...state,
        student: state.student.filter((grd) => grd.id !== parseInt(payload)),
        loading: false,
      };

    default:
      return state;
  }
};

export default studentReducer;
