import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_THESIS,
  DELETE_THESIS,
  GET_THESIS,
  GET_THESIS_DETAILS,
  UPDATE_THESIS,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";
import { FORM_POST_CONFIG, JSON_POST_CONFIG } from "../constants/PostConfig";

//GET THESIS LIST
export const getThesisList =
  (search = "", type = null, page = 1, limit = 3) =>
  async (dispatch) => {
    // console.log("HITT");
    try {
      let obj = {};
      if (type && type !== "All") {
        obj = { type };
      }
      if (search) {
        obj = { ...obj, search };
      } else {
        obj = { ...obj, search: "" };
      }
      const res = await axios.post(
        `${BASE_URL}/api/thesis/filter?pageSize=${limit}&page=${
          page ? page : 1
        }`,
        JSON.stringify(obj),
        JSON_POST_CONFIG
      );
      // console.log(res);

      dispatch({
        type: GET_THESIS,
        payload: res.data,
      });
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

//GET THESIS LIST
export const getThesisDetails = (id) => async (dispatch) => {
  // console.log("HITT");
  try {
    const res = await axios.get(`${BASE_URL}/api/thesis/${id}`);
    // console.log(res);

    dispatch({
      type: GET_THESIS_DETAILS,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

// CREATE THESIS
export const createThesis = (values, pdf) => async (dispatch) => {
  let formData = new FormData();
  formData.append("title", values.title);
  formData.append("author", values.author);
  formData.append("supervisor", values.supervisor);
  formData.append("type", values.type);
  formData.append("abstract", values.abstract);

  if (pdf) {
    formData.append("pdf", pdf);
  }
  try {
    // TODO ::: API CALL
    const res = await axios.post(
      `${BASE_URL}/api/thesis`,
      formData,
      FORM_POST_CONFIG
    );
    dispatch({
      type: CREATE_THESIS,
    });
    dispatch(getThesisList());
    toast.success("Thesis added successfully");
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

// UPDATE THESIS
export const updateThesis = (values, pdf, id) => async (dispatch) => {
  let formData = new FormData();
  formData.append("title", values.title);
  formData.append("author", values.author);
  formData.append("supervisor", values.supervisor);
  formData.append("type", values.type);
  formData.append("abstract", values.abstract);

  if (pdf) {
    formData.append("pdf", pdf);
  }
  try {
    // TODO ::: API CALL
    const res = await axios.patch(
      `${BASE_URL}/api/thesis/${id}`,
      formData,
      FORM_POST_CONFIG
    );
    dispatch({
      type: UPDATE_THESIS,
    });

    dispatch(getThesisList());
    toast.success("Thesis updated successfully");
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

//DELETE Thesis
export const deleteThesis = (id) => async (dispatch) => {
  try {
    await axios.delete(`${BASE_URL}/api/thesis/${id}`);
    dispatch({
      type: DELETE_THESIS,
      payload: id,
    });
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
