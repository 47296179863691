import { Badge, Button, Divider, Group, Text } from "@mantine/core";
import { useModals } from "@mantine/modals";
import React from "react";
import { useNavigate } from "react-router-dom";
import { deleteThesis } from "../../../actions/Thesis.action";
import { getDashboardData } from "../../../actions/Dashboard.action";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";

import HtmlEditor from "devextreme-react/html-editor";

const ThesisCard = ({
  title,
  abstract,
  id,
  type,
  author,
  supervisor,
  edit,
  getDashboardData,
  deleteThesis,
  del,
  isAuth,
}) => {
  const navigate = useNavigate();
  const modals = useModals();
  const deleteHandeler = (id) =>
    modals.openConfirmModal({
      title: "Delete this thesis",
      centered: true,
      children: (
        <Text size="md">
          Are you sure you want to delete this thesis? This action is
          destructive and you can not undo it.
        </Text>
      ),
      labels: { confirm: "Delete Thesis", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: () => confirmHandeler(id),
    });

  const confirmHandeler = async (id) => {
    let check = await deleteThesis(id);
    if (check === true) {
      getDashboardData();
      toast.success("Thesis deleted successfully");
    } else {
      toast.error("Something went wrong");
    }
  };
  return (
    <Card radius="md" className="h-100">
      <Card.Body className="p-3">
        <Group justify="center" align="center" mt="md" mb="xs">
          <Text fw={500} align="center">
            {title}
          </Text>
          <div className="d-flex justify-content-center w-100">
            <Badge
              color="pink"
              variant="filled"
              style={{ textTransform: "capitalize" }}
            >
              {type}
            </Badge>
          </div>
        </Group>

        <Text align="center" size="sm" color="blue" weight={"bold"}>
          Supervisor: {supervisor}
        </Text>
        <HtmlEditor
          readOnly
          stylingMode="filled"
          defaultValue={author}
          style={{ color: "#000" }}
        ></HtmlEditor>
      </Card.Body>
      <Card.Footer>
        {/* <Text size="sm">Author: {author}</Text> */}
        <div className="h-100">
          <Button
            color="blue"
            fullWidth
            radius="md"
            onClick={() => {
              if (!isAuth) {
                toast("Please login to see details of this thesis.");
              } else {
                navigate(`/thesis/${id}`);
              }
            }}
          >
            See Thesis
          </Button>
          <Button.Group style={{ width: "100%", marginTop: "auto" }}>
            {edit === true && isAuth ? (
              <Button
                color="orange"
                fullWidth
                mt="md"
                radius="md"
                onClick={() => navigate(`/admin/thesis/${id}/edit`)}
              >
                Edit
              </Button>
            ) : (
              <></>
            )}
            {del === true && isAuth ? (
              <Button
                color="red"
                fullWidth
                mt="md"
                radius="md"
                onClick={() => deleteHandeler(id)}
              >
                Delete
              </Button>
            ) : (
              <></>
            )}
          </Button.Group>
        </div>
      </Card.Footer>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { getDashboardData, deleteThesis })(
  ThesisCard
);
