import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import {
  Form as BootstrapForm,
  Button,
  Card,
  Col,
  Container,
  InputGroup,
  Row,
} from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { createThesis, updateThesis } from "../../actions/Thesis.action";
import { BASE_URL } from "../../constants/URL";
import styles from "./AddThesisForm.module.css";
import { ThesisType } from "../../constants/ThesisType";
import { FileInput, Select } from "@mantine/core";
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
const headerValues = [false, 1, 2, 3, 4, 5];

const AddThesisForm = ({ createThesis, data, edit, updateThesis }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();

  const onSubmitHandeler = async (values) => {
    if (!image && edit === false) {
      toast("Please select PDF");
      return;
    }
    setIsLoading(true);
    let check = edit
      ? await updateThesis(values, image, data.id)
      : await createThesis(values, image);
    if (check === true) {
      setTimeout(() => {
        setIsLoading(false);
        navigate("/admin/thesis");
      }, 1000);
    } else {
      setIsLoading(false);
    }
  };

  let initVals = {
    title: edit === true && data ? data.title : "",
    supervisor: edit === true && data ? data.supervisor : "",
    type: edit === true && data ? data.type : ThesisType[0],
    abstract: edit === true && data ? data.abstract : "",
    author: edit === true && data ? data.author : "",
  };

  const SignupSchema = Yup.object().shape({
    title: Yup.string().required("Title is required!"),
    supervisor: Yup.string().required("Supervisor is required!"),
    abstract: Yup.string().required("Abstract is required!"),
    author: Yup.string().required("Author is required!"),
  });
  return (
    <Container className="pb-5">
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <h1 className="fs-4 fw-normal py-3">
            Fill the form to {edit ? "edit" : "add"} thesis
          </h1>
          <Formik
            initialValues={initVals}
            validationSchema={SignupSchema}
            enableReinitialize
            onSubmit={(values) => onSubmitHandeler(values)}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="title" className="d-block">
                      Thesis Title
                    </label>
                    {errors.title && touched.title ? (
                      <small className="text-danger pt-2">{errors.title}</small>
                    ) : null}
                  </div>
                  <Field
                    as={BootstrapForm.Control}
                    placeholder="Type title ..."
                    name="title"
                    isValid={!errors.title && touched.title}
                    type="text"
                    className={`${styles.input} w-100`}
                    isInvalid={errors.title && touched.title}
                  />
                </InputGroup>
                <Row>
                  <Col md={12}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center ">
                        <label htmlFor="supervisor" className="d-block">
                          Supervisor's Name
                        </label>
                        {errors.supervisor && touched.supervisor ? (
                          <small className="text-danger pt-2">
                            {errors.supervisor}
                          </small>
                        ) : null}
                      </div>
                      <Field
                        as={BootstrapForm.Control}
                        placeholder="Type supervisor's name..."
                        name="supervisor"
                        type="text"
                        className={`${styles.input} w-100`}
                        isInvalid={errors.supervisor && touched.supervisor}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={12}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center pb-2">
                        <label htmlFor="author" className="d-block">
                          Authors
                        </label>
                        {errors.author && touched.author ? (
                          <small className="text-danger">{errors.author}</small>
                        ) : null}
                      </div>
                      <HtmlEditor
                        height="200px"
                        defaultValue={
                          edit === true && data && data.author
                            ? data.author
                            : null
                        }
                        value={values.author}
                        onValueChange={(e) => setFieldValue("author", e)}
                      >
                        <Toolbar multiline={true}>
                          <Item name="undo" />
                          <Item name="redo" />
                          <Item name="separator" />
                          <Item name="size" acceptedValues={sizeValues} />
                          <Item name="font" acceptedValues={fontValues} />
                          <Item name="separator" />
                          <Item name="bold" />
                          <Item name="italic" />
                          <Item name="strike" />
                          <Item name="underline" />
                          <Item name="separator" />
                          <Item name="alignLeft" />
                          <Item name="alignCenter" />
                          <Item name="alignRight" />
                          <Item name="alignJustify" />
                          <Item name="separator" />
                          <Item name="orderedList" />
                          <Item name="bulletList" />
                          <Item name="separator" />
                          <Item name="header" acceptedValues={headerValues} />
                          <Item name="separator" />
                          <Item name="color" />
                          <Item name="background" />
                          <Item name="separator" />
                          <Item name="link" />
                          <Item name="image" />
                          <Item name="separator" />
                          <Item name="clear" />
                          <Item name="codeBlock" />
                          <Item name="blockquote" />
                          <Item name="separator" />
                          <Item name="insertTable" />
                          <Item name="deleteTable" />
                          <Item name="insertRowAbove" />
                          <Item name="insertRowBelow" />
                          <Item name="deleteRow" />
                          <Item name="insertColumnLeft" />
                          <Item name="insertColumnRight" />
                          <Item name="deleteColumn" />
                        </Toolbar>
                      </HtmlEditor>
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center pb-2">
                        <label htmlFor="abstract" className="d-block">
                          Abstract
                        </label>
                        {errors.abstract && touched.abstract ? (
                          <small className="text-danger">
                            {errors.abstract}
                          </small>
                        ) : null}
                      </div>
                      <HtmlEditor
                        height="725px"
                        defaultValue={
                          edit === true && data && data.abstract
                            ? data.abstract
                            : null
                        }
                        value={values.abstract}
                        onValueChange={(e) => setFieldValue("abstract", e)}
                      >
                        <Toolbar multiline={true}>
                          <Item name="undo" />
                          <Item name="redo" />
                          <Item name="separator" />
                          <Item name="size" acceptedValues={sizeValues} />
                          <Item name="font" acceptedValues={fontValues} />
                          <Item name="separator" />
                          <Item name="bold" />
                          <Item name="italic" />
                          <Item name="strike" />
                          <Item name="underline" />
                          <Item name="separator" />
                          <Item name="alignLeft" />
                          <Item name="alignCenter" />
                          <Item name="alignRight" />
                          <Item name="alignJustify" />
                          <Item name="separator" />
                          <Item name="orderedList" />
                          <Item name="bulletList" />
                          <Item name="separator" />
                          <Item name="header" acceptedValues={headerValues} />
                          <Item name="separator" />
                          <Item name="color" />
                          <Item name="background" />
                          <Item name="separator" />
                          <Item name="link" />
                          <Item name="image" />
                          <Item name="separator" />
                          <Item name="clear" />
                          <Item name="codeBlock" />
                          <Item name="blockquote" />
                          <Item name="separator" />
                          <Item name="insertTable" />
                          <Item name="deleteTable" />
                          <Item name="insertRowAbove" />
                          <Item name="insertRowBelow" />
                          <Item name="deleteRow" />
                          <Item name="insertColumnLeft" />
                          <Item name="insertColumnRight" />
                          <Item name="deleteColumn" />
                        </Toolbar>
                      </HtmlEditor>
                    </InputGroup>
                  </Col>
                </Row>
                <Select
                  label="Thesis Type"
                  data={ThesisType}
                  value={values.type}
                  onChange={(e) => (e ? setFieldValue("type", e) : false)}
                />
                <Row className="mt-3">
                  <Col md={12}>
                    {" "}
                    <InputGroup className="mb-3 d-flex flex-column">
                      <div className="d-flex  ">
                        <label htmlFor="image" className="d-block">
                          PDF
                        </label>
                      </div>{" "}
                      <FileInput
                        placeholder="Select PDF file"
                        value={image}
                        onChange={setImage}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                {edit && data && data?.pdf && data.pdf !== "" ? (
                  <a
                    href={`${BASE_URL}/api/uploads/${data.pdf}`}
                    target="_blank"
                  >
                    PDF File
                  </a>
                ) : (
                  <></>
                )}
                <div className="pt-4">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn_primary"
                    disabled={isLoading}
                  >
                    {isLoading
                      ? "Loading..."
                      : edit
                      ? "Edit Thesis"
                      : "Add Thesis"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default connect(null, {
  createThesis,
  updateThesis,
})(AddThesisForm);
