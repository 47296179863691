import React from "react";
import styles from "./Footer.module.css";

const Footer = ({}) => {
  return (
    <div className={styles.wrapper}>
      <span className="text-center d-block py-4">
        &copy;{new Date().getFullYear()} Mechanical Engineering Department,
        RUET. Developed by{" "}
        <a
          href="https://tanvirmahin.com"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.copy}
        >
          Tanvir Mahin
        </a>
      </span>
    </div>
  );
};

export default Footer;
