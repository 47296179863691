export const JSON_POST_CONFIG = {
  headers: {
    "Content-Type": "application/json",
  },
};
export const FORM_POST_CONFIG = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
