import React from "react";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import ThesisList from "../../components/ThesisList/ThesisList";
import NavbarComp from "../../components/shared/NavbarComp/NavbarComp";
import Footer from "../../components/Footer/Footer";

const LandingPage = () => {
  return (
    <>
      <NavbarComp />
      <Header>
        <Hero />
      </Header>
      <h2 className="text-center pt-4 pb-4">Recently Added</h2>
      <ThesisList />
      <Footer />
    </>
  );
};

export default LandingPage;
