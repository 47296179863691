import React, { useEffect, useState } from "react";
import { Card, Container, Spinner, Table } from "react-bootstrap";

import { Link, useSearchParams } from "react-router-dom";
import styles from "./StudentList.module.css";
import { connect } from "react-redux";
import { AiFillEdit } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { useModals } from "@mantine/modals";
import { Input, Text } from "@mantine/core";
import { toast } from "react-toastify";
import { deleteStudent, getStudentList } from "../../actions/Student.action";
import { Button as MTButton } from "@mantine/core";
import { getDashboardData } from "../../actions/Dashboard.action";

const StudentList = ({
  list,
  getStudentList,
  deleteStudent,
  getDashboardData,
}) => {
  const [rawList, setRawList] = useState(list === null ? [] : list);
  const modals = useModals();
  const [searchParams] = useSearchParams();
  const [role, setRole] = useState("All");
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (list === null) {
      getStudentList(null);

      if (searchParams.get("role")) {
        setRole(searchParams.get("role"));
      }
    }
    if (list !== null) {
      setRawList(list);
    }
  }, [list]);

  const deleteHandeler = (id) =>
    modals.openConfirmModal({
      title: "Delete this user",
      centered: true,
      children: (
        <Text size="md">
          Are you sure you want to delete this user? This action is destructive
          and you can not undo it.
        </Text>
      ),
      labels: { confirm: "Delete User", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: () => confirmHandeler(id),
    });

  const confirmHandeler = async (id) => {
    let check = await deleteStudent(id);
    if (check === true) {
      getDashboardData();
      toast.success("User deleted successfully");
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <Container className="pb-4">
      <Card bg="white" text="dark" className={`crd shadow mb-4`}>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search user by name or email"
                style={{
                  minWidth: 230,
                }}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column pe-md-5 pe-0">
              {/* <h3>Filter Users</h3> */}
              <MTButton.Group>
                <MTButton
                  onClick={() => setRole("All")}
                  variant={role === "All" ? "filled" : "outline"}
                >
                  All
                </MTButton>
                <MTButton
                  onClick={() => setRole("TEACHER")}
                  variant={role === "TEACHER" ? "filled" : "outline"}
                >
                  Teacher
                </MTButton>
                <MTButton
                  onClick={() => setRole("STUDENT")}
                  variant={role === "STUDENT" ? "filled" : "outline"}
                >
                  Student
                </MTButton>
                <MTButton
                  onClick={() => setRole("ADMIN")}
                  variant={role === "ADMIN" ? "filled" : "outline"}
                >
                  Admin
                </MTButton>
              </MTButton.Group>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="text-right">
                <Link
                  to="/admin/user/add"
                  className={"btn_primary text-decoration-none"}
                >
                  <BsFileEarmarkPlus /> Add User
                </Link>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="crd p-md-4 pb-md-0 p-2">
        <Card.Body>
          {rawList === null ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: 600 }}
            >
              <Spinner variant="dark" animation="grow" />
            </div>
          ) : rawList.length === 0 ? (
            <span className="text-center fs-4 d-block pb-3">No User Found</span>
          ) : (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>

                    <th>
                      <div className="d-flex justify-content-center align-items-center">
                        Actions
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rawList !== null &&
                    rawList
                      .filter((item) =>
                        role === "All" ? true : item.role === role
                      )
                      .filter(
                        (item) =>
                          search === "" ||
                          (item.name &&
                            item.name
                              .toLowerCase()
                              .includes(search.toLowerCase())) ||
                          (item.email &&
                            item.email
                              .toLowerCase()
                              .includes(search.toLowerCase()))
                      )
                      .map((userItem, i) => (
                        <tr key={userItem.id}>
                          <td>{i + 1}</td>
                          <td>{userItem.name} </td>
                          <td>{userItem.email}</td>
                          <td>{userItem.role}</td>

                          <td>
                            <div className="d-flex justify-content-center align-items-center">
                              <Link
                                to={`/admin/user/${userItem.id}/edit`}
                                className={` fw-bold  me-4 ${styles.link}`}
                              >
                                <AiFillEdit />
                              </Link>
                              <span
                                onClick={() => deleteHandeler(userItem.id)}
                                className={`fw-bold  ${styles.link}`}
                              >
                                <BsTrash />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.student.student,
});

export default connect(mapStateToProps, {
  getStudentList,
  deleteStudent,
  getDashboardData,
})(StudentList);
