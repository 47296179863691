import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import styles from "./Pagination.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";

const Pagination = ({ total, url, current, onClick }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const clickHandeler = (page) => {
    if (page <= total && page >= 1 && parseInt(current) !== parseInt(page)) {
      if (onClick) {
        onClick(page);
      }
      navigate(
        `${url}?page=${page}&role=${
          searchParams.get("role") ? searchParams.get("role") : "All"
        }`
      );
    }
  };
  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.icon} ${
          parseInt(current) !== 1 ? styles.active : ""
        }`}
        onClick={() =>
          clickHandeler(parseInt(current) !== 1 ? parseInt(current) - 1 : 1)
        }
      >
        <AiOutlineLeft />
      </div>
      <div className={styles.text}>
        <span>Page </span>
        <span>
          {current} of {total}
        </span>
      </div>
      <div
        className={`${styles.icon} ${
          parseInt(current) !== parseInt(total) ? styles.active : ""
        }`}
        onClick={() =>
          clickHandeler(
            parseInt(current) !== parseInt(total)
              ? parseInt(current) + 1
              : parseInt(total)
          )
        }
      >
        <AiOutlineRight />
      </div>
    </div>
  );
};

export default Pagination;
