import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { FaGraduationCap, FaNewspaper, FaRegUser } from "react-icons/fa";
import { connect } from "react-redux";
import { getDashboardData } from "../../actions/Dashboard.action";
import Layout from "../../components/shared/Layout/Layout";
import StatCard from "../../components/shared/StatCard/StatCard";
import { useNavigate } from "react-router-dom";

const DashboardPage = ({ data, getDashboardData, role }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!data) {
      getDashboardData();
    }
    if (!role || role !== "ADMIN") {
      navigate("/");
    }
  }, [role]);
  return (
    <Layout>
      {data ? (
        <Row className="pt-0">
          <Col md={4} className="py-3">
            <StatCard
              title="Total Student"
              icon={<FaGraduationCap />}
              count={data.student}
            />
          </Col>
          <Col md={4} className="py-3">
            <StatCard
              title="Total Teacher"
              icon={<FaRegUser />}
              count={data.teacher}
            />
          </Col>

          <Col md={4} className="py-3">
            <StatCard
              title="Total Thesis"
              icon={<FaNewspaper />}
              count={data.thesis}
            />
          </Col>

          <Col md={12} className="py-3">
            <span className="d-block fs-4 border-bottom pt-4">Quick Links</span>
          </Col>
          <Col md={4} className="py-3">
            <StatCard
              title="Add Thesis"
              icon={<FaNewspaper />}
              link="/admin/thesis/add"
            />
          </Col>
          <Col md={4} className="py-3">
            <StatCard
              title="Add Student"
              icon={<FaGraduationCap />}
              link="/admin/user/add"
            />
          </Col>

          <Col md={4} className="py-3">
            <StatCard
              title="Add Teacher"
              icon={<FaRegUser />}
              link="/admin/user/add?role=TEACHER"
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  data: state.auth.dashboard,
  role: state.auth.role,
});
export default connect(mapStateToProps, { getDashboardData })(DashboardPage);
