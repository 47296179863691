import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";

import { Divider, Input, Button as MTButton } from "@mantine/core";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { connect } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { getThesisList } from "../../actions/Thesis.action";
import { ThesisType } from "../../constants/ThesisType";
import ThesisCard from "../shared/ThesisCard/ThesisCard";
import Pagination from "../Pagination/Pagination";

const ThesisListAdmin = ({ list, getThesisList }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [role, setRole] = useState("All");
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (searchParams.get("role")) {
      getThesisList(
        search,
        searchParams.get("role"),
        searchParams.get("page") ? searchParams.get("page") : 1
      );
      setRole(searchParams.get("role"));
    } else {
      getThesisList(
        search,
        null,
        searchParams.get("page") ? searchParams.get("page") : 1
      );
    }
  }, []);

  const searchHandeler = async () => {
    getThesisList(search, role, 1);
    setSearchParams(`page=1&role=${role}`);
  };

  return (
    <Container className="pb-4">
      <Card bg="white" text="dark" className={`crd shadow mb-4`}>
        <Card.Body>
          <div className="d-flex justify-content-center align-items-center  pt-2 pb-3">
            <div className="text-right">
              <Link
                to="/admin/thesis/add"
                className={"btn_primary text-decoration-none"}
              >
                <BsFileEarmarkPlus /> Add Thesis
              </Link>
            </div>
          </div>
          <Divider />
          <Row>
            <Col md={5} className="d-flex align-items-center  py-md-0 py-2">
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search by title, author or supervisor name"
                style={{ width: "100%" }}
              />
            </Col>
            <Col
              md={5}
              className="d-flex justify-content-center align-items-center flex-column pe-md-5 pe-0 py-md-0 py-2"
            >
              {/* <h3>Filter Users</h3> */}
              <MTButton.Group>
                <MTButton
                  onClick={() => setRole("All")}
                  variant={role === "All" ? "filled" : "outline"}
                >
                  All
                </MTButton>
                <MTButton
                  onClick={() => setRole(ThesisType[0])}
                  variant={role === ThesisType[0] ? "filled" : "outline"}
                  style={{ textTransform: "capitalize" }}
                >
                  {ThesisType[0]}
                </MTButton>
                <MTButton
                  onClick={() => setRole(ThesisType[1])}
                  variant={role === ThesisType[1] ? "filled" : "outline"}
                >
                  Masters
                </MTButton>
              </MTButton.Group>
            </Col>
            <Col md={2} className="d-flex justify-content-end">
              <MTButton onClick={searchHandeler} leftIcon={<FaSearch />}>
                Search
              </MTButton>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {list === null ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: 600 }}
        >
          <Spinner variant="dark" animation="grow" />
        </div>
      ) : !list.data || list?.data.length === 0 ? (
        <span className="text-center fs-4 d-block pb-3">No Thesis Found</span>
      ) : (
        <>
          <Container fluid>
            <Row>
              {list !== null &&
                list.data.map((thesisItem, i) => (
                  <Col md={4} key={i} className="p-3">
                    <ThesisCard {...thesisItem} edit={true} del />
                  </Col>
                ))}
              <Col
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <Pagination
                  current={
                    searchParams.get("page") ? searchParams.get("page") : 1
                  }
                  url="/admin/thesis"
                  onClick={(pg) => {
                    getThesisList(
                      search,
                      searchParams.get("role")
                        ? searchParams.get("role")
                        : null,
                      pg
                    );
                  }}
                  total={list !== null && list.totalPages ? list.totalPages : 1}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.thesis.thesis,
});

export default connect(mapStateToProps, {
  getThesisList,
})(ThesisListAdmin);
