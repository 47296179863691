import { Routes, Route, BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import {
  AddStudentPage,
  AddThesisPage,
  DashboardPage,
  LoginPage,
  StudentListPage,
  ThesisListPage,
  ThesisDetailsPage,
} from "./views";
import { ToastContainer } from "react-toastify";
import PrivateOutlet from "./utils/PrivateOutlet";
import { useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import setAuthToken from "./utils/setAuthToken";
import "devextreme/dist/css/dx.light.css";
import LandingPage from "./views/LandingPage/LandingPage";
import "./App.css";
import { authUserAction } from "./actions/Dashboard.action";
import { connect } from "react-redux";
function App({ authUserAction }) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("thesis-archive-ruet")) {
      setAuthToken(localStorage.getItem("thesis-archive-ruet"));
      authUserAction();
    }
    loadingHandeler();

    return () => {
      setLoading(false);
    };
  }, [loading]);

  const loadingHandeler = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };
  return (
    <>
      {loading ? (
        <div className={`landing_loader`}>
          <span className="d-block text-light fs-4 py-4">Loading...</span>
          <ScaleLoader color={"#f68a3c"} loading={loading} size={150} />
        </div>
      ) : (
        <></>
      )}
      <ToastContainer newestOnTop theme="dark" />
      <BrowserRouter>
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />

          <Route path="/*" element={<PrivateOutlet />}>
            <>
              {/* PRIVATE ROUTES */}
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="thesis/:id" element={<ThesisDetailsPage />} />

              <Route path="admin/user" element={<StudentListPage />} />
              <Route path="admin/user/add" element={<AddStudentPage />} />
              <Route
                path="admin/user/:id/edit"
                element={<AddStudentPage edit={true} />}
              />
              <Route path="admin/thesis" element={<ThesisListPage />} />
              <Route path="admin/thesis/add" element={<AddThesisPage />} />
              <Route
                path="admin/thesis/:id/edit"
                element={<AddThesisPage edit={true} />}
              />
            </>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default connect(null, { authUserAction })(App);
