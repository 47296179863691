import { Button, Divider } from "@mantine/core";
import HtmlEditor from "devextreme-react/html-editor";
import React, { useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import { FaLink } from "react-icons/fa";
import { connect } from "react-redux";
import { getThesisDetails } from "../../actions/Thesis.action";
import { BASE_URL } from "../../constants/URL";

const ThesisDetails = ({ id, getThesisDetails, thesis }) => {
  useEffect(() => {
    if (id) {
      getThesisDetails(id);
    }
  }, [id]);

  if (!thesis || thesis.id !== id) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: 600 }}
      >
        <Spinner variant="dark" animation="grow" />
      </div>
    );
  }
  return (
    <Container>
      <h3 className="text-center">{thesis.title}</h3>
      <h5 className="text-primary text-center">
        Supervised By: {thesis.supervisor}
      </h5>
      <h3 className="text-primary text-center pt-3">Author</h3>
      <HtmlEditor
        readOnly
        stylingMode="filled"
        defaultValue={thesis.author}
        style={{ color: "#000" }}
      ></HtmlEditor>
      <Divider />
      <h3 className="text-primary text-center pt-4">Abstract</h3>
      <HtmlEditor
        readOnly
        stylingMode="filled"
        defaultValue={thesis.abstract}
        style={{ color: "#000" }}
      ></HtmlEditor>
      <div className="text-center py-4">
        <Button
          variant="filled"
          color="blue"
          size="lg"
          onClick={() => {
            if (typeof document !== "undefined") {
              var link = document.createElement("a");
              link.href = `${BASE_URL}/api/uploads/${thesis.pdf}`;
              link.target = "_blank";
              link.click();
            }
          }}
        >
          <FaLink className="me-2" /> View PDF
        </Button>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  thesis: state.thesis.selected_thesis,
});

export default connect(mapStateToProps, { getThesisDetails })(ThesisDetails);
