import { combineReducers } from "redux";
import authReducer from "./Auth.reducer";
import studentReducer from "./Student.reducer";
import thesisReducer from "./Thesis.reducer";

const reducer = combineReducers({
  auth: authReducer,
  student: studentReducer,
  thesis: thesisReducer,
});

export default reducer;
