import React from "react";
import HeroImg from "../../Assets/RUET.png";
import styles from "./Hero.module.css";
import Login from "../Login/Login";
import { Button } from "@mantine/core";
import { FaSignInAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const Hero = ({ isAuth }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.hero}>
      <div className={styles.right}>
        <img src={HeroImg} alt="hero" />
        <h2 className="pt-3">
          Rajshahi University of Engineering and Technology
        </h2>
        <h4 className="mb-4">Mechanical Engineering Theses Collection</h4>
        {!isAuth ? (
          <Button size="lg" onClick={() => navigate("/login")}>
            <FaSignInAlt className="me-2" /> Login Now
          </Button>
        ) : (
          <></>
        )}
      </div>
      {/* <div className={`${styles.left} my-auto`}>
        <div className="pb-5">
          <Login />
        </div>
      </div> */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, null)(Hero);
